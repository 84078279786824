import Head from 'next/head'
import ldJSON from 'public/ld.json'
import { globalCSS } from './templates'
import getNRScript from './newrelic'

const PRODUCTION = process.env.NODE_ENV === 'production'

export default function DefaultHead({ children, isSEO }) {
    return (
        <>
            <Head>
                <meta {...({ charSet: 'UTF-8' } as any)} />
                <link rel="dns-prefetch preconnect" href="https://www.googletagmanager.com/"></link>
                <link rel="dns-prefetch preconnect" href="https://www.google-analytics.com/"></link>
                <link rel="dns-prefetch preconnect" href="https://fonts.googleapis.com/"></link>
                <link rel="dns-prefetch preconnect" href="https://fonts.gstatic.com/"></link>
                <link rel="dns-prefetch preconnect" href="https://storage.googleapis.com/"></link>
                <link rel="icon" href="/static/favicon.ico" type="image/x-icon" />
                <link rel="manifest" href="/static/manifest.json" />
                {!isSEO ? <link rel="canonical" href="https://www.acko.com/" /> : null}
                <meta
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                    name="viewport"
                />
                <meta name="theme-color" content="#582CDB" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta
                    content="Buy/Renew vehicle insurance online instantly from ACKO General Insurance Company without any hassle. View personalised car & bike insurance prices, enjoy stress free claims & more."
                    name="description"
                />
                {!isSEO ? (
                    <meta
                        name="keywords"
                        content="vehicle insurance,vehicle insurance online,car insurance,bike insurance"
                    />
                ) : null}
                <title>Buy Car, Bike, Health insurance online - Acko General Insurance Company</title>
                {PRODUCTION ? (
                    <script
                        key="sw"
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `
if ('serviceWorker' in window.navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/sw.js').catch(function(){});
    });
}
`,
                        }}
                    ></script>
                ) : null}
                <script
                    key="ldjson"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(ldJSON),
                    }}
                ></script>
                {PRODUCTION ? (
                    <script
                        key="gtm"
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TZVHFQH');
`,
                        }}
                    ></script>
                ) : null}
                {PRODUCTION && isSEO ? (
                    <script async key="gtagjs" src="https://www.googletagmanager.com/gtag/js?id=UA-92272448-1"></script>
                ) : null}
                {PRODUCTION && isSEO ? (
                    <script
                        key="gtag"
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-92272448-1');
`,
                        }}
                    ></script>
                ) : null}
                {PRODUCTION ? (
                    <script
                        key="newrelic"
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: getNRScript(
                                process.env.NEXT_PUBLIC_NEW_RELIC_SCRIPT_LICENSE_KEY || '',
                                process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID || ''
                            ),
                        }}
                    ></script>
                ) : null}
            </Head>
            {/* {PRODUCTION ? (
                <noscript
                    key="gtm-noscript"
                    dangerouslySetInnerHTML={{
                        __html: `
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZVHFQH" height="0" width="0" style="display:none;visibility:hidden"></iframe>
`,
                    }}
                ></noscript>
            ) : null} */}
            <style jsx global>
                {globalCSS}
            </style>
            {children}
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"
                rel="stylesheet"
            ></link>
            {PRODUCTION ? (
                <script
                    key="sentry"
                    src="https://browser.sentry-cdn.com/5.18.0/bundle.min.js"
                    integrity="sha384-9M0M/t9hmfACAyfoyFXXyzRbljCren5OjRJhHwZHJzuzFt02ZB67XZO27O1tml6L"
                    {...({ crossOrigin: 'anonymous' } as any)}
                ></script>
            ) : null}
            {PRODUCTION ? (
                <script
                    key="sentry-init"
                    dangerouslySetInnerHTML={{
                        __html: `Sentry.init({ dsn: '${process.env.SENTRY_DSN}' });`,
                    }}
                ></script>
            ) : null}
        </>
    )
}
