import css from 'styled-jsx/css'

export const globalCSS = css.global`
    @charset "utf-8";
    html,
    body,
    div,
    span,
    iframe,
    h2,
    a,
    img {
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: middle;
        background: transparent;
        margin: 0;
        padding: 0;
    }

    input {
        resize: none;
        margin: 0;
        padding: 0;
    }

    * {
        padding: 0;
        margin: 0;
        font-display: swap;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    html {
        min-height: 100vh;
    }

    body {
        font-family: 'Inter';
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    a {
        backface-visibility: hidden;
    }

    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    img[data-src] {
        width: 100%;
    }
`
