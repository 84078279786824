import { AppProps, default as App, AppContext } from 'next/app'
import DefaultHead from '@components/DefaultHead'
// import Message from '@components/Message'
import { initGlobals } from '@utils/globals'

interface IProps {
    isMobile: boolean
    isSEO: boolean
}

class Container extends App<IProps & AppProps, {}, {}> {
    static async getInitialProps({ Component, ctx }: AppContext) {
        let pageProps = {}
        const setPageProps = res => (pageProps = res)
        // Run independant async tasks parallely
        await Promise.all([
            Component.getInitialProps
                ? Promise.resolve(Component.getInitialProps(ctx)).then(setPageProps)
                : Promise.resolve(),
        ])
        return {
            pageProps,
            isMobile: ctx.query.isMobile === 'true',
            isSEO: ctx.query.isSEO === 'true',
        }
    }

    componentDidMount() {
        initGlobals()
        r2d2({
            ekind: 'page_rendered',
            okind: 'tracker',
            oid: cookie('trackerid'),
        }).catch(() => null)
    }

    render() {
        const { Component, pageProps, isSEO } = this.props
        return (
            <DefaultHead isSEO={isSEO}>
                <Component {...pageProps} />
            </DefaultHead>
        )
    }
}

export default Container
