// @ts-nocheck
export default function init() {
    Promise.prototype.finally =
        Promise.prototype.finally ||
        function (callback) {
            return this.then(
                value => this.constructor.resolve(callback()).then(() => value),
                reason =>
                    this.constructor.resolve(callback()).then(() => {
                        throw reason
                    })
            )
        }
}
